//import {ajax} from './ajax/src/ajax';
//let ajaxFilter = document.querySelector('.ajaxFilter')
let ajaxForm = document.getElementById('ajaxForm');
let ajaxResidence = document.querySelector('.ajaxFilter')
let ajaxResult = document.getElementById('ajaxResult')

if(ajaxForm) {
    ajaxForm.addEventListener('submit', (e) => {
        e.preventDefault();
        initAjax()
    });
}

function initAjax()
{
    document.querySelector('.ajaxFilter-result').classList.add("blur-md", 'overflow-hidden');

    let formData = new FormData(ajaxResidence);
    formData.append("action", 'ajaxFilter')

    let request = new XMLHttpRequest();
    console.log('ajaxurl', ajaxFilter)
    request.open('POST', ajaxFilter.ajaxUrl, true);
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

    request.onprogress = function () {
        console.log('LOADING', request.readyState); // readyState will be 3
    };

    request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
            ajaxResult.classList.remove("blur-md", 'overflow-hidden');
            ajaxResult.innerHTML = request.response;
            const y = ajaxResult.getBoundingClientRect().top + window.scrollY;

            window.scroll({
                top: y - 117,
                behavior: 'smooth'
            });
            document.querySelector('.spinner').classList.add('hidden');

        }
    };

    request.send(new URLSearchParams(formData));

}
